<template>
  <div>
    <v-menu :close-on-content-click="false" right offset-x>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon-table-facture"
          v-on="on"
          v-bind="attrs"
          @click.prevent="openModal"
          title="La liste de TVA"
        >
          <font-awesome-icon :icon="['fas', 'circle-info']" class="" />
        </div>
      </template>
      <v-card>
        <v-card-text class="body-card center-text">
          <v-data-table
            :headers="computedFields"
            :items="tvaList"
            hide-default-footer
            hide-action
            disable-filtering
            disable-sort
            disable-pagination
            class="table-dialog"
            item-class="text-center"
            :items-per-page="tvaList.length"
          >
            <template v-slot:[`item.base_tva`]="{ item }">
              <td class="custom-cell">{{ item.tva }}</td>
            </template>
            <template v-slot:[`item.total_ht`]="{ item }">
              <td class="custom-cell">{{ item.total_ht }} €</td>
            </template>
            <template v-slot:[`item.total_ttc`]="{ item }">
              <td class="custom-cell">{{ item.total_ttc }} €</td>
            </template>
            <template v-slot:[`item.reste_charge`]="{ item }">
              <td class="custom-cell">{{ item.reste_charge }} €</td>
            </template>

            <template v-slot:[`item.total_tva`]="{ item }">
              <td class="custom-cell">{{ item.total_tva }} €</td></template
            >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    tvaList: { required: true }
  },
  data() {
    return {
      menu: false,
      error: false
    };
  },
  computed: {
    computedFields() {
      return [
        {
          value: 'base_tva',
          text: 'Taux TVA ',
          show: true,
          sortable: false
        },
        {
          value: 'total_tva',
          text: 'Total TVA',
          show: true,
          sortable: false
        },
        {
          value: 'total_ht',
          text: 'Total HT',
          show: true,
          sortable: false
        },
        {
          value: 'total_ttc',
          text: 'Total TTC',
          show: true,
          sortable: false
        },
        {
          value: 'reste_charge',
          text: 'Reste à charge',
          show: true,
          sortable: false
        }
      ];
    }
  },
  mounted() {},
  methods: {
    openModal() {
      this.menu = true;
    },
    hideModal() {
      this.menu = false;
      this.error = false;
    }
  }
};
</script>
